import * as React from 'react';

import LayoutPage from '../components/LayoutPage';
import HomeScreen from '../components/HomeScreen';
// import AppLoader from '../components/AppLoader';
import dynamic from 'next/dynamic';
const AppLoader = dynamic(() => import('../components/AppLoader'), {
  ssr: false,
});

function HomePage(props) {
  return (
    <LayoutPage>
      <AppLoader>
        <HomeScreen {...props} />
      </AppLoader>
    </LayoutPage>
  );
}

export default HomePage;
